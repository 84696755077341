@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-dark: #000000;
  --primary-light: #404040;
  /* background: white; */
  background-image: linear-gradient(85deg, hsl(19, 95%, 68%), #e293ac, #a43bcb, #84d4d8, #fcce90);
}

.animate-blob {
  animation: blob 8s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

@layer base {
  button {
    @apply px-8 py-4 mr-5 border rounded-full text-black;
  }

  li {
    @apply p-4 text-sm font-bold hover:underline;
  }
}

@media  screen and (max-width: 1366px) {
  body p {
    font-size: 30px;
  }
}

@media  screen and (max-width: 1366px) {
  body p {
    font-size: 30px;
  }
}

@font-face {
  font-family: "Metavoria";
  src: local(Playfulion),
    url(./fonts/Metavoria.otf) format('opentype');
}

@font-face {
  font-family: "ComicSans";
  src: url(./fonts/COMICSANS.TTF) format('truetype'),
    url(./fonts/COMICSANSBOLD.TTF) format('truetype'),
    url(./fonts/COMICSANSBOLDITALIC.TTF) format('truetype'),
    url(./fonts/COMICSANSITALIC.TTF) format('truetype');
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 18%;
  z-index: 1000; /* Ensure the navbar is above other elements */
  /* background-color: white; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Optional: Add a subtle shadow for better separation */
}

.gradient-custom {
  background: linear-gradient(60deg, #FB9060, #e293ac, #a43bcb, #84d4d8, #fcce90);
}

.fixed-hamburger {
  position: fixed;
  top: 0;
  /* width: fit-content; */
}

.roboto-tiny {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
  font-style: normal;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.rock-salt-regular {
  font-family: "Metavoria", cursive;
  font-weight: 1000;
  font-style: normal;
}

.mystery-quest-header {
  font-family: "ComicSans", system-ui;
  font-weight: 500;
  font-style: normal;
  font-size: 70px;
}

.mystery-quest-modal {
  font-family: "Mystery Quest", system-ui;
  font-weight: 500;
  font-style: normal;
  font-size: 40px;
}

.bubblegum-sans-subheader {
  font-family: "ComicSans", sans-serif;
  font-weight: 100;
  font-size: 20px;
  word-spacing: 3px;
}

.comic-sans-tiny {
  font-family: "ComicSans", sans-serif;
  font-weight: 50;
  font-size: 14px;
}
.comic-sans-small {
  font-family: "ComicSans", sans-serif;
  font-weight: 100;
  font-size: 18px;
}

.comic-sans-medium {
  font-family: "ComicSans", sans-serif;
  font-weight: 100;
  font-size: 30px;
  word-spacing: 3px;
}

.comic-sans-large {
  font-family: "ComicSans", sans-serif;
  font-weight: 00;
  font-size: 60px;
  word-spacing: 3px;
}
.comic-sans-hero {
  font-family: "ComicSans", sans-serif;
  font-weight: 100;
  font-size: 50px;
  word-spacing: 3px;
}

.bubblegum-sans-link {
  font-family: "ComicSans", sans-serif;
  font-weight: 50;
  font-size: 17px;
  word-spacing: 3px;
}

.btn.outline-button {
  border-color: #fcce90;
  color: black;
  background-color: #fcce90;
  border: 2px solid #a43bcb;
  padding: 10px;
}

.Example__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;
}
/* BookPage.css */

.book-page {
  font-family: 'Arial, sans-serif';
  padding: 20px;
}

/* .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
} */

.book-card {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
}

.book-image {
  flex: 1;
  text-align: center;
}

.book-cover {
  max-width: 75%;
  height: 75%;
  border-radius: 10px;
}

.book-details {
  flex: 2;
  /* padding: 20px; */
}

.book-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.book-level,
.book-price,
.book-description {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.purchase-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.purchase-button:hover {
  background-color: #0056b3;
}

.book-description-and-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.book-description-section,
.book-contents {
  flex: 1;
  margin: 10px;
}

.contents-image-container {
  text-align: center;
  margin-top: 20px;
}

.contents-image {
  max-width: 80%;
  height: 80%;
}

.sample-images-section {
  margin-top: 40px;
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.description-list {
  list-style: disc;
  padding-left: 20px;
}

.sample-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.sample-image {
  max-width: 30%;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .book-card {
    flex-direction: column;
  }

  .book-details {
    padding: 10px;
  }

  .book-cover {
    max-width: 100%;
    height: auto;
    border-radius: 10;
  }

  .book-description-and-contents {
    flex-direction: column;
  }

  .sample-image {
    max-width: 100%;
  }
}

/* EmailCapturePage.css */

/* Background animation */
@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-blue-100 {
  animation: gradient-bg 15s ease infinite;
  background: linear-gradient(-45deg, #f3e7e9, #e3eeff, #e3f7fd, #f5e7e7);
  background-size: 400% 400%;
}

/* Additional form styling */
input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.5);
}

button:hover {
  background-color: #4f46e5;
}

/* Message styling */
.text-green-600 {
  font-weight: bold;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blob {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
}

@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
@keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
/*End Animations*/
/*


-- Start BackGround Animation 
*/
.logo-container {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  max-height: 200px; /* Adjust this to your preferred navbar height */
}

.img-logo {
  max-height: 100%;
  width: auto;
}
.area {
  background-image: linear-gradient(85deg, #fb9160, #e293ac, #a43bcb, #84d4d8, #fcce90);
  /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8); */
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 96%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /* background: #fcce90; */
  animation: animate 3s linear infinite; /* Reduced from 5s to 3s */
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 6s; /* Reduced from 12s to 6s */
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  animation-duration: 3s; /* Reduced to match base animation duration */
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 9s; /* Reduced from 18s to 9s */
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 3s; /* Reduced to match base animation duration */
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  animation-duration: 6s; /* Reduced to balance animation delay */
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  animation-duration: 15s; /* Reduced from 30s to 15s */
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 22.5s; /* Reduced from 45s to 22.5s */
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 17.5s; /* Reduced from 35s to 17.5s */
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 5.5s; /* Reduced from 11s to 5.5s */
}

@keyframes animate {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-1000px) rotate(360deg);
  }
}


@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Modal Content (image) */
.modal-content {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  display: block;
}

/* Caption of Modal Image (Image Text) - You can style this if needed */
.caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
.close {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
      transform: scale(0);
  }
  to {
      transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Thumbnail image */
.thumbnail {
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px; /* Adjust as necessary */
  transition: all 0.3s ease-in-out;
}

.thumbnail:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}


/*
-- End BackGround Animation 
*/

/* .gradient-primary {
  --tw-gradient-stops: theme
} */